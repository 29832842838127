import { gql, useQuery } from "@apollo/client";
import { Avatar } from "components/avatar";
import { colors } from "components/colors";
import {
  DropdownMenu,
  DropdownMenuOptionType,
} from "components/dropdown_menu/dropdown_menu";
import { Icon } from "components/iconv2";
import { Text, TextSize } from "components/text_v2";
import { useUserRoleQuery } from "hooks/use_user_role_query";
import { signInModalState } from "providers/app_loader";
import { useAuthV2 } from "providers/authv2";
import { appConfig } from "providers/config";
import { usePostBookingsFeatureFlag } from "providers/splitio";
import React, { useMemo } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  AppHeaderV3DesktopQuery,
  BookingRequestsAdminStatusesQuery,
  BookingRequestsManagerStatusesQuery,
  BookingRequestStatus,
} from "./graphql.generated";
import { ROUTES } from "./routes";
import { useAnalytics } from "../providers/analytics";

interface ProfileDropDownMenuProps {
  currentUser: AppHeaderV3DesktopQuery["currentUser"];
  isAdmin: boolean;
  onInviteClick: React.Dispatch<React.SetStateAction<boolean>>;
  isBudgetManager?: boolean;
  isPro?: boolean;
  transparent?: boolean;
}

export const BookingRequestsLabel = (props: {
  size?: TextSize;
  color?: string;
}) => {
  const { size = "sm", color = colors.brand.blackcore } = props;
  const { isAdmin, isManager } = useUserRoleQuery();
  const { data: bookingRequestsAdminData } =
    useQuery<BookingRequestsAdminStatusesQuery>(
      bookingRequestsStatusesAdminQuery,
      {
        skip: !isAdmin,
      },
    );
  const { data: bookingRequestsManagerData } =
    useQuery<BookingRequestsManagerStatusesQuery>(
      bookingRequestsStatusesManagerQuery,
      {
        skip: isAdmin,
      },
    );

  const numberOfPendingRequests = useMemo(() => {
    if (isAdmin) {
      return bookingRequestsAdminData?.currentUser?.organization?.bookingRequests?.filter(
        (r) => r.status === BookingRequestStatus.Pending,
      ).length;
    }
    if (isManager) {
      return bookingRequestsManagerData?.currentUser?.groupBudget?.bookingRequests?.filter(
        (r) => r.status === BookingRequestStatus.Pending,
      ).length;
    }
  }, [
    bookingRequestsAdminData?.currentUser?.organization?.bookingRequests,
    bookingRequestsManagerData?.currentUser?.groupBudget?.bookingRequests,
    isAdmin,
    isManager,
  ]);

  if (!numberOfPendingRequests) {
    return <Text customColor={color}>Requests</Text>;
  }

  return (
    <View style={styles.requestLabelWrapper}>
      <Text size={size} customColor={color}>
        Requests ({numberOfPendingRequests})
      </Text>
      <View style={styles.dot} />
    </View>
  );
};

export function ProfileDropDownMenu(props: ProfileDropDownMenuProps) {
  const {
    currentUser,
    isAdmin,
    onInviteClick,
    isBudgetManager,
    isPro,
    transparent,
  } = props;

  return (
    <ProfileDropDownV2
      currentUser={currentUser}
      isAdmin={isAdmin}
      onInviteClick={onInviteClick}
      isBudgetManager={isBudgetManager}
      isPro={isPro}
      transparent={transparent}
    />
  );
}

function ProfileDropDownV2(props: ProfileDropDownMenuProps) {
  const {
    currentUser,
    isAdmin,
    onInviteClick,
    isBudgetManager = false,
    isPro = false,
    transparent = false,
  } = props;
  const history = useHistory();
  const { logout } = useAuthV2();
  const postBookingFeatureFlag = usePostBookingsFeatureFlag();
  const { authenticated } = useAuthV2();
  const currentPage = history.location.pathname;
  const [, setShowLoginModal] = useRecoilState(signInModalState);
  const analytics = useAnalytics();
  const items: DropdownMenuOptionType[] = [
    {
      label: "Find workspaces",
      linkTo: "/search",
      testID: "find-workspaces-nav",
      color: currentPage === "/search" ? colors.brand.eggplantcore : undefined,
    },
    {
      label: "Bookings",
      linkTo: postBookingFeatureFlag
        ? ROUTES.BOOKINGS.path
        : ROUTES.RESERVATIONS.path,
      testID: "bookings-nav",
      color:
        currentPage ===
        (postBookingFeatureFlag
          ? ROUTES.BOOKINGS.path
          : ROUTES.RESERVATIONS.path)
          ? colors.brand.eggplantcore
          : colors.brand.blackcore,
    },
    {
      label: "Team",
      linkTo: ROUTES.TEAM_SPACES.path,
      testID: "my-team-nav",
      color:
        currentPage === ROUTES.TEAM_SPACES.path
          ? colors.brand.eggplantcore
          : colors.brand.blackcore,
    },
    {
      label: "Profile",
      linkTo: ROUTES.USER_PROFILE.path,
      testID: "my-profile-nav",
      color:
        currentPage === ROUTES.USER_PROFILE.path
          ? colors.brand.eggplantcore
          : colors.brand.blackcore,
    },
    "-",
    isAdmin && {
      label: "Manage organization",
      linkTo: isPro ? ROUTES.TODAY.path : ROUTES.ANALYTICS.path,
      testID: "manage-organization-nav",
      color:
        currentPage === (isPro ? ROUTES.TODAY.path : ROUTES.ANALYTICS.path)
          ? colors.brand.eggplantcore
          : colors.brand.blackcore,
    },
    !isAdmin &&
      isBudgetManager && {
        label: "Manage budget",
        linkTo: `/admin/budgets/${currentUser?.groupBudget?.id}`,
        testID: "manage-budget-nav",
        color:
          currentPage === ROUTES.GROUP_BUDGET_DETAIL.path
            ? colors.brand.eggplantcore
            : undefined,
      },
    (isAdmin || isBudgetManager) &&
      isPro && {
        label: (
          <BookingRequestsLabel
            color={
              currentPage === ROUTES.BOOKING_REQUESTS.path
                ? colors.brand.eggplantcore
                : colors.brand.blackcore
            }
          />
        ),
        linkTo: ROUTES.BOOKING_REQUESTS.path,
        testID: "requests-nav",
      },
    {
      label: "Google Calendar add-on",
      iconName: "outgoing",
      onClick: () => window.open(appConfig.chromeWebstoreUrl, "_blank"),
      testID: "calendar-add-on-nav",
    },
    "-",
    {
      label: "Help",
      linkTo: ROUTES.HELP.path,
      testID: "help-nav",
      color:
        currentPage === ROUTES.HELP.path
          ? colors.brand.eggplantcore
          : colors.brand.blackcore,
    },
    { label: "Log out", onClick: () => logout(), testID: "logout-nav" },
  ];

  const unAuthenticatedItems: DropdownMenuOptionType[] = [
    {
      label: "Find workspaces",
      linkTo: "/search",
      testID: "find-workspaces-nav",
      color: currentPage === "/search" ? colors.brand.eggplantcore : undefined,
    },
    {
      label: "Bookings",
      linkTo: postBookingFeatureFlag
        ? ROUTES.BOOKINGS.path
        : ROUTES.RESERVATIONS.path,
      testID: "bookings-nav",
      color:
        currentPage ===
        (postBookingFeatureFlag
          ? ROUTES.BOOKINGS.path
          : ROUTES.RESERVATIONS.path)
          ? colors.brand.eggplantcore
          : colors.brand.blackcore,
    },
    {
      label: "Team",
      linkTo: ROUTES.TEAM_SPACES.path,
      testID: "my-team-nav",
      color:
        currentPage === ROUTES.TEAM_SPACES.path
          ? colors.brand.eggplantcore
          : colors.brand.blackcore,
    },
    "-",

    {
      label: "Log in or sign up",
      testID: "login-or-signup-nav",
      onClick: () => {
        analytics.event(`Click Header Login`);
        setShowLoginModal(true);
      },
      color:
        currentPage === ROUTES.SIGN_IN.path
          ? colors.brand.eggplantcore
          : colors.brand.blackcore,
    },
  ];

  return (
    <DropdownMenu
      testID="header-profile-dropdown"
      maxHeight={500}
      sameMinWidth
      sameWidth={false}
      items={authenticated ? items : unAuthenticatedItems}
      renderAnchor={({ isOpen, setIsOpen }) => (
        <View style={styles.buttonsAndAvatar}>
          {authenticated && (
            <>
              <Pressable
                testID="invite-members-header-button"
                onPress={() => onInviteClick(true)}
              >
                <View style={styles.button}>
                  <Icon
                    name="userPlus"
                    color={transparent ? "whitecore" : "eggplantcore"}
                    filled={transparent}
                    size="md"
                  />
                </View>
              </Pressable>
              <Pressable
                testID="header-help-button"
                onPress={() => history.push("/help")}
              >
                <View style={styles.button}>
                  <Icon
                    name="chatQuestion"
                    color={transparent ? "whitecore" : "eggplantcore"}
                    filled={transparent}
                    size="md"
                  />
                </View>
              </Pressable>
            </>
          )}

          <Pressable
            onPress={() => setIsOpen(!isOpen)}
            testID="header-user-profile"
          >
            <Avatar
              size="md"
              name={currentUser?.fullName}
              source={{ uri: currentUser?.picture }}
              appearance={transparent ? "default" : "outline"}
            />
          </Pressable>
        </View>
      )}
    />
  );
}

const styles = StyleSheet.create({
  buttonsAndAvatar: {
    flexDirection: "row",
    alignItems: "center",
    gap: 20,
    justifyContent: "flex-end",
  },
  button: {
    flexDirection: "row",
    gap: 4,
    alignItems: "center",
    height: 40,
  },
  requestLabelWrapper: {
    flexDirection: "row",
    gap: 4,
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: colors.brand.eggplantcore,
  },
});

const bookingRequestsStatusesAdminQuery = gql`
  query BookingRequestsAdminStatuses {
    currentUser {
      id
      organization {
        id
        bookingRequests {
          id
          status
        }
      }
    }
  }
`;

const bookingRequestsStatusesManagerQuery = gql`
  query BookingRequestsManagerStatuses {
    currentUser {
      id
      groupBudget {
        id
        bookingRequests {
          id
          status
        }
      }
    }
  }
`;
