import { GridV2 } from "components/gridv2";
import {
  HomePage__LocationDetailsFragment,
  HomePage__SpaceDetailsFragment,
  HomePageLocations__LocationDetailsFragment,
  SpacesSort,
  SpaceType,
} from "core/graphql.generated";
import { groupBy } from "lib/array_utils";
import { calculateDistance } from "lib/map_utils";
import { useMediaQuery } from "lib/media_query";
import { SEARCH_PLACE_HISTORY_LOCALSTORAGE_KEY } from "pages/homev2/hooks/use_search_history";
import { MapboxFeature, toCoordinates } from "pages/homev2/mapbox";
import { useMapImprovementFeatureFlag } from "providers/splitio";
import { useHomeSearchParamsQuery } from "../hooks/use_home_search_params";
import { ListingDayOfficeCardV2 } from "./listing_day_office_card_v2";
import { ListingSpaceCardV2 } from "./listing_space_card_v2";
import { LocationCard } from "./location_card";

interface SpaceListCardProps {
  spaces: HomePage__SpaceDetailsFragment[];
  onPreviewLocation: (locationId: HomePage__LocationDetailsFragment) => void;
  locations?: HomePageLocations__LocationDetailsFragment[];
}

export function SpaceListCards(props: SpaceListCardProps) {
  const { spaces: sp, onPreviewLocation, locations: lo } = props;
  const { spaceType, sort } = useHomeSearchParamsQuery();
  const mq = useMediaQuery();
  const searchPlace = localStorage.getItem(
    SEARCH_PLACE_HISTORY_LOCALSTORAGE_KEY,
  );
  const searchHistory = searchPlace ? JSON.parse(searchPlace) : [];
  const lastSearchPlace: MapboxFeature = searchHistory[0];
  const isMapImprovement = useMapImprovementFeatureFlag();
  let spaces = sp;
  let locations = lo;

  if (isMapImprovement && sort === SpacesSort.Distance && lastSearchPlace) {
    if (spaces) {
      spaces = [...spaces].sort(
        (a, b) =>
          calculateDistance(
            {
              latitude: toCoordinates(lastSearchPlace.center).lat,
              longitude: toCoordinates(lastSearchPlace.center).lng,
            },
            {
              latitude: a.location.address.latitude || 0,
              longitude: a.location.address.longitude || 0,
            },
          ) -
          calculateDistance(
            {
              latitude: toCoordinates(lastSearchPlace.center).lat,
              longitude: toCoordinates(lastSearchPlace.center).lng,
            },
            {
              latitude: b.location.address.latitude || 0,
              longitude: b.location.address.longitude || 0,
            },
          ),
      );
    }
    if (locations) {
      locations = [...locations].sort(
        (a, b) =>
          calculateDistance(
            {
              latitude: toCoordinates(lastSearchPlace.center).lat,
              longitude: toCoordinates(lastSearchPlace.center).lng,
            },
            {
              latitude: a.address.latitude || 0,
              longitude: a.address.longitude || 0,
            },
          ) -
          calculateDistance(
            {
              latitude: toCoordinates(lastSearchPlace.center).lat,
              longitude: toCoordinates(lastSearchPlace.center).lng,
            },
            {
              latitude: b.address.latitude || 0,
              longitude: b.address.longitude || 0,
            },
          ),
      );
    }
  }

  const officeLocations =
    spaceType === SpaceType.DayOffice && spaces
      ? groupBy(spaces, (s) => s.location.id)
      : {};

  return (
    <GridV2 columns={mq.deviceQuery.mobile ? 1 : 2}>
      {spaceType === SpaceType.DayOffice &&
        Object.values(officeLocations).map((spaces) => (
          <ListingDayOfficeCardV2
            spaces={spaces}
            key={`${spaces[0]?.location?.id}-day-office`}
            onPreviewLocation={onPreviewLocation}
          />
        ))}

      {(spaceType === SpaceType.MeetingRoom ||
        spaceType === SpaceType.DayPass) &&
        spaces.map((space) => (
          <ListingSpaceCardV2 spaceType={spaceType} space={space} />
        ))}
      {!spaceType &&
        locations?.map((location) => (
          <LocationCard key={location.id} location={location} />
        ))}
    </GridV2>
  );
}
